import PropTypes from 'prop-types';
import useIsMobile from '../../../hooks/useIsMobile';
import * as styles from './scopeOfWork.module.css';

const ScopeOfWork = ({data}) => {
    const isMobile = useIsMobile(900);
    const getList = arr => arr.map((item, idx) => <li key={`smthng-${idx}`}>{item}</li>);

    return (
        <div className={styles.wrapper}>
            {!isMobile && <h4>Scope of work</h4>}
            <div className={styles.content}>
                <div className={styles.column}>
                    <h2>Milujeme...</h2>
                    <ul>
                        {getList(data.pros)}
                    </ul>
                </div>
                <div className={styles.column}>
                    <h2>Vytáčí nás...</h2>
                    <ul>
                        {getList(data.cons)}
                    </ul>
                </div>
            </div>
        </div>
    );
};

ScopeOfWork.propTypes = {
    data: PropTypes.object,
};

export default ScopeOfWork;
