import PropTypes from 'prop-types';
import * as styles from './whatWeDo.module.css';

const WhatWeDo = ({data}) => {
    const rows = Object.values(data).map((row, idx) => (
        <div key={`features-row-${idx}`} className={styles.row}>
            <div className={styles.leftSide}>
                {row.title
                    ? (
                        <>
                            {row.title}
                        </>
                    )
                    : (
                        <div className={styles.icons}>
                            {row.icons.map((Icon, i) => <Icon key={`icon-${i}`}/>)}
                        </div>
                    )
                }
            </div>
            <div className={styles.rightSide}>
                <p>{row.text}</p>
            </div>
        </div>
    ));

    return (
        <div className={styles.wrapper}>
            {rows}
        </div>
    );
};

WhatWeDo.propTypes = {
    data: PropTypes.object,
};

export default WhatWeDo;
