import PropTypes from 'prop-types';
import {Header, Layout} from '../components/layout';
import pageTypes from '../utils/enums/pageType';
import openPositions from '../data/career';
import Hero from '../components/layout/hero/hero';
import WhatWeDo from '../components/career/careerWhatWeDo/whatWeDo';
import Divider from '../components/divider/divider';
import ScopeOfWork from '../components/career/scopeOfWork/scopeOfWork';
import {ContactForm} from '../components';
import useIsMobile from '../hooks/useIsMobile';
import * as styles from './careerDetail.module.css';

const CareerDetail = ({
    pageContext: {id},
}) => {
    const isMobile = useIsMobile(900);
    const {
        title,
        detail: {
            headerColor,
            level,
            focus,
            description,
            whatWeDo,
            scopeOfWork,
        },
    } = openPositions.filter(position => position.detailSlug === id)[0];

    // const caseHeroImageRef = useRef();
    // const caseHeroTitleRef = useRef();
    // const {entry} = useTransitionState();
    // const entryTransition = useTriggerTransition({
    //     to: location.pathname,
    //     entry: {
    //         length: 0.5,
    //         state: {
    //             pageType: pageTypes.case,
    //             nextPageType: pageTypes.case,
    //         },
    //     },
    // });

    // useEffect(() => {
    //     if (entry && Object.keys(entry.state).length > 0 && entry.state.isTransition) {
    //         return;
    //     }

    //     entryAnimation({
    //         pageRef: document.body,
    //         caseHeroImage: caseHeroImageRef.current,
    //         caseHeroTitle: caseHeroTitleRef.current,
    //         isTransition: false,
    //     });
    //     entryTransition({});
    // // eslint-disable-next-line
    // }, []);

    const headerDetails = (
        <div className={styles.headerDetail}>
            <div className={styles.headerDetailColumn}>
                <h4>Úroveň</h4>
                <p>{level}</p>
            </div>
            <div className={styles.headerDetailColumn}>
                <h4>Zaměření</h4>
                <p>{focus}</p>
            </div>
        </div>
    );

    return (
        <Layout>
            <Header pageType={pageTypes.case} headerColor={headerColor}>
                <Hero
                    headerType = {headerColor}
                    title = {title}
                    description = {headerDetails}
                    backLinkPath = {'/career'}
                    backLinkText = 'Kariéra'
                />
            </Header>
            <div className={styles.careerDetail} data-animation-copy="fade-in-entry">
                <div className={styles.mainDescription}>
                    <p>{description}</p>
                </div>
                <WhatWeDo data={whatWeDo}/>
                {!isMobile && <Divider/>}
                <ScopeOfWork data={scopeOfWork}/>
                <div className={styles.contactFormWrapper}>
                    <div className={styles.contactForm}>
                        <h1>Je to práce pro tebe?<br/>
                            Chceš se dozvědět víc?
                        </h1>
                        <p>Napiš nám, přihoď portfolio se svými projekty z digitálního prostředí a pojďme se potkat.</p>
                        <ContactForm isContact={false} postion={id}/>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

CareerDetail.propTypes = {
    pageContext: PropTypes.shape({
        id: PropTypes.string,
    }),
};

export default CareerDetail;
